import { useState } from 'react';
import { useWidgetContext } from 'contexts/WidgetProvider';
import { appAssets } from 'constants/assets';
import { FaFileCsv, FaFileExcel, FaHtml5 } from 'react-icons/fa';
import './exportingoptions.css';
import { useStore as useGlobalStore } from 'store/useGlobalStore';

const ExportToFile = () => {
  const useHtmlExport = useGlobalStore((state) => state.configDashboard.useHtmlExport) ?? false;
  const { setState } = useWidgetContext();
  const DownloadIcon = appAssets.icons.DOWNLOAD_ICON;

  const exportHandler = (val: string) => {
    setState({ action: val });
    toggleMenu();
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="meatball-menu calendar">
      <div className="meatball-icon" onClick={toggleMenu}>
        <DownloadIcon />
      </div>
      {isOpen && (
        <div className="menu-options">
          <ul>
            <li>
              {FaFileCsv ? (
                <FaFileCsv size={18} onClick={() => exportHandler('EXPORT_TO_CSV')} />
              ) : null}
            </li>
            <li>
              {FaFileExcel ? (
                <FaFileExcel size={18} onClick={() => exportHandler('EXPORT_TO_XLSX')} />
              ) : null}
            </li>
            {useHtmlExport && (
              <li>
                {FaHtml5 ? (
                  <FaHtml5 size={18} onClick={() => exportHandler('EXPORT_TO_HTML')} />
                ) : null}
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

export default ExportToFile;
