import { Skeleton, SkeletonVariants } from '@cx/ui';
import { useRef, useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';

const columns = [
  {
    name: <Skeleton variant={SkeletonVariants.rectangular} />,
    selector: (row: any) => row.cel1,
    cell: () => <Skeleton variant={SkeletonVariants.rectangular} />,
  },
  {
    name: <Skeleton variant={SkeletonVariants.rectangular} />,
    selector: (row: any) => row.cel2,
    cell: () => <Skeleton variant={SkeletonVariants.rectangular} />,
  },
  {
    name: <Skeleton variant={SkeletonVariants.rectangular} />,
    selector: (row: any) => row.cel3,
    cell: () => <Skeleton variant={SkeletonVariants.rectangular} />,
  },
  {
    name: <Skeleton variant={SkeletonVariants.rectangular} />,
    selector: (row: any) => row.cel4,
    cell: () => <Skeleton variant={SkeletonVariants.rectangular} />,
  },
];
const computeRows = (itemsCount: number) => {
  return Array.from({ length: itemsCount }, (_, i) => ({
    id: i,
    col1: '',
    col2: '',
    col3: '',
    col4: '',
  }));
};
const DynamicTableLoadingSkeleton = () => {
  const parentRef = useRef<HTMLDivElement | null>(null);
  const [itemsCount, setItemsCount] = useState(5);
  const [data, setData] = useState<any[]>(computeRows(5));

  const calculateItems = () => {
    if (parentRef.current) {
      const { height } = parentRef?.current?.getBoundingClientRect() ?? 0;
      const itemSize = 55;
      const rows = Math.max(5, Math.floor(height / itemSize));
      setItemsCount(rows);
    }
  };

  useEffect(() => {
    const dummyData = computeRows(itemsCount);
    setData(dummyData);
  }, [itemsCount]);

  useEffect(() => {
    calculateItems(); // Initial calculation
    const resizeObserver = new ResizeObserver(() => calculateItems());
    if (parentRef.current) resizeObserver.observe(parentRef.current);

    return () => resizeObserver.disconnect();
  }, []);

  return (
    <div
      className="cardOverlay"
      ref={parentRef}
      style={{
        height: '100%',
      }}
    >
      <DataTable
        columns={columns}
        data={data}
        noHeader={true}
        persistTableHead
        progressPending={!(data.length > 0)}
      />
    </div>
  );
};

export default DynamicTableLoadingSkeleton;
