import React, { useEffect } from 'react';
import './TACardWidget.scss';
import { useAxios } from 'hooks/useAxios';
import { getDataFromSingleEndpoint } from 'services/Torogoz/TogorozApi';
import { useStore as useGlobalStore } from 'store/useGlobalStore';
import libraryComponents from 'components';
import LoadingSpinner from 'core-ui/LoadingSpinner/LoadingSpinner';
import CardTitle from 'components/CardTitle/CardTitle';
import CardFooterActions from 'components/CardFooterActions/CardFooterActions';

interface TACardWidgetProps {
  selectedWidget?: any;
  setSelectedWidget?: any;
  [x: string]: any;
}

const TACardWidget: React.FC<TACardWidgetProps> = ({
  selectedWidget,
  setSelectedWidget,
  ...widget
}) => {
  const { payload, displayName } = widget;
  const ChildComponent = libraryComponents[widget.alias];
  const { response, axiosFetch, loading, error } = useAxios();
  const { endpoints, dataSourceType } = useGlobalStore((state) => ({
    endpoints: state.endpoints,
    dataSourceType: state.dataSourceType,
  }));

  const getData = async () => {
    await getDataFromSingleEndpoint(endpoints.getWidgetData, payload, axiosFetch);
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div>
        <div
          className={`ta-card ${loading ? 'ta-card-loading' : ''}`}
          onClick={setSelectedWidget ? () => setSelectedWidget(widget) : undefined}
        >
          {loading ? null : (
            <CardTitle
              displayName={displayName}
              viewName=""
              isMetricWidget={false}
              showWarning={false}
              warningValidData={false}
              isActions={false}
            />
          )}
          {loading ? (
            <LoadingSpinner />
          ) : (
            <ChildComponent
              {...widget}
              response={response}
              loading={loading}
              error={error}
              dataSourceType={dataSourceType}
            />
          )}
          {loading ? null : (
            <CardFooterActions {...{ actions: widget?.options?.actions, response }} />
          )}
        </div>
        {!loading && selectedWidget?.id === widget.id ? (
          <div className="ta-selected">
            <div className="arrow"></div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default TACardWidget;
