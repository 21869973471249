import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import Grid from 'features/Grid/Grid';
import './core-ui/styles/chartStyles.scss';
import './core-ui/styles/tabs.scss';
import './core-ui/styles/tooltip.scss';
import './core-ui/styles/modal.scss';
import './core-ui/styles/formStyles.scss';
import { useStore } from 'store/useGlobalStore';
import { initialGlobalState } from './store/initialGlobalState';
import { SignalRProvider } from 'contexts/SignalRProvider';
import { ThemeProvider } from '@cx/ui';

const element = document.getElementById('diy-dashboard');
const jsconfig = document.getElementById('diy-config');
const jsChatconfig = document.getElementById('chat-config');
const jsTextAnalytics = document.getElementById('text-analytics-config');
const configSurvey: any = JSON.parse(jsconfig?.textContent || '{}');
const configChat: any = JSON.parse(jsChatconfig?.textContent || '{}');
const configTextAnalytics: any = JSON.parse(jsTextAnalytics?.textContent || '{}');
// eslint-disable-next-line prettier/prettier
const root = createRoot(element!);
const { urls } = initialGlobalState.configDashboard;
const App = () => {
  const { dashboardView, setState } = useStore((state) => ({
    dashboardView: state.dashboardView,
    setState: state.setState,
  }));

  useEffect(() => {
    const dashboardOptions = {
      survey: configSurvey,
      chat: configChat,
      'text-analytics': configTextAnalytics,
    };

    const dashboardConfig = dashboardOptions[dashboardView];

    setState({
      configDashboard: {
        ...dashboardConfig,
        urls: {
          ...urls,
          ...dashboardConfig.urls,
        },
      },
    });
  }, [dashboardView]);

  return (
    <div>
      {Object.keys(configSurvey || configChat || configTextAnalytics).length ? (
        <Grid />
      ) : (
        <div>404 - Not Found</div>
      )}
    </div>
  );
};

root.render(
  <React.StrictMode>
    <SignalRProvider>
      <ThemeProvider
        cacheConfig={{
          key: 'peacock-app',
          // @ts-expect-error - property nonce does not exist on type CacheConfig
          prepend: true,
          container: element!,
          nonce: window.globalNonceKey,
        }}
      >
        <App />
      </ThemeProvider>
    </SignalRProvider>
  </React.StrictMode>
);
