import DynamicTableLoadingSkeleton from './DynamicTableLoadingSkeleton';
import './LoadingSpinner.scss';

interface LoadingSpinnerProps {
  text?: string;
  alias?: string;
}

const LoadingSpinner = (props: LoadingSpinnerProps) => {
  if (props.alias === 'DynamicTable') {
    return <DynamicTableLoadingSkeleton />;
  }
  return (
    <div className="diy-loading">
      <div className="diy-spinner"></div>
      {props.text && <div className="diy-text">{props.text}</div>}
    </div>
  );
};

export default LoadingSpinner;
